import { Fragment, useEffect, useMemo, useState } from 'react';
import Button from '../../components/Button';
import { useRequestData } from '../../service/requestService';
import { useSnipesApp } from '../../SnipesApp';
import CopySvg from '../../svg/CopySvg';
import './style.css';

const WinPage = () => {
  const SnipesApp = useSnipesApp();

  const { prize, couponTxt, requestSecondCall, prizeOptions } = useRequestData()
  const [copyToClipBoardClass, setcopyToClipBoardClass] = useState('')
  const [showFooter, setShowFooter] = useState(false)

  const CTAUrl = useMemo(()=>{
    const locale = SnipesApp.customer.locale;
    const country = locale?.split('_')[1];

    switch(country){
      case 'DE':
        return 'https://www.snipes.com/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'AT':
        return 'https://www.snipes.at/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'NL':
        return 'https://www.snipes.nl/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'ES':
        return 'https://www.snipes.es/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'FR':
        return 'https://www.snipes.fr/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'PL':
        return 'https://www.snipes.pl/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'IT':
        return 'https://www.snipes.it/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'BE':
        return 'https://www.snipes.be/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      case 'PT':
        return 'https://www.snipes.pt/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
      default:
        return 'https://www.snipes.com/c/cliqueprices?utm_campaign=cliqueweek_scratch_app';
    }
  },[SnipesApp.customer.locale])

  useEffect(() => {
    requestSecondCall()
  },[requestSecondCall])

  useEffect(() => {
    setTimeout(() => {
      setShowFooter(true)
    },1000)
    
  },[])

  const onCopyToClipboard = async() => {
    setTimeout(async () => {
      const text = await navigator.clipboard.readText();
      console.log(text);
    }, 2000);
      await navigator.clipboard.writeText(couponTxt).then(function() {
      setcopyToClipBoardClass('copied');
      setTimeout(()=>{setcopyToClipBoardClass('')},1000)
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  

  const onClick = () => {
    console.log('CTA CLICK', CTAUrl)
    SnipesApp.navigate('CategoryLandingPage', {category: {id:'cliqueprices', name:'cliqueprices'}, page:{
    url: CTAUrl
    }})}
    
  const getPrizeOption = prizeOptions[prize.type]

  const renderCard = () => prize.type === 'GIFT_CARD' ? 'gray_card.png' : 'colors_card.png' 

  const getPrizeLogo = () => {
    switch ( prize.type) {
      
      case "VOUCHER":
        return 'Voucher.png'   
      case "GIFT_CARD":
        return 'Voucher.png'   
      case "GIFT_CARD_FLATRATE":
        return 'Voucher.png'   

      case "NY_TRIP":
        return 'Trip.png'

      case "E_BIKE":
        return 'E-bike.png'
            
      default:
        return 'Voucher.png';
    }
  }

  const getPrizeDescription = () => {
    switch ( prize.type) {
      
      case "VOUCHER":
        return <p className="terms-conditions-text win-action-terms-conditions-code">*Code can be used on snipes.com, via the SNIPES app or in store until 17.07.2022. Selected items and sale excluded.</p>

      case "GIFT_CARD":
        return <p className="terms-conditions-text win-action-terms-conditions-description-big">Please check your contact information in your account to ensure they are correct and complete. You will receive your price within the next month.</p>

      case "GIFT_CARD_FLATRATE":
        return <p className="terms-conditions-text win-action-terms-conditions-description-big">You will receive 1x 200€ every month for 1 year. We will contact you <span className="description-text__highlight">within the next 2 weeks</span> for more details. Please check your contact information in your account to ensure they are correct and complete.</p>

      case "NY_TRIP":
      case "E_BIKE":
        return <p className="terms-conditions-text win-action-terms-conditions-description-big">We will contact you <span className="description-text__highlight">within the next 2 weeks</span> for more details. Please check your contact information in your account to ensure they are correct and complete.</p>
            
      default:
        return '';
    }
  }

  const getPrizeValue = () => {
    switch ( prize.type) {
      
      case "VOUCHER":
        return '15%'   
      case "GIFT_CARD":
        return '100 €'   
      case "GIFT_CARD_FLATRATE":
        return '200 €'   
            
      default:
        return 'Voucher.png';
    }
  }

  const getPrizeBtn = () => {
    switch ( prize.type) {
      
      case "VOUCHER":
        return 'Shop Now'  

      case "GIFT_CARD":
      case "GIFT_CARD_FLATRATE":
        return 'Start Your Wishlist'   

      case "NY_TRIP":
        return 'What To Pack'

      case "E_BIKE":
        return 'Ride In Style'
            
      default:
        return 'Start Your Wishlist';
    }
  }

  return (
    <Fragment>
      <img className="win-copy" src={getPrizeLogo()} alt="logo img" />
      {(prize.type === 'VOUCHER' || prize.type === 'GIFT_CARD' || prize.type === 'GIFT_CARD_FLATRATE') && <div className="win-card-container">
        <p className="win-card-value">{getPrizeValue()}</p>
        <div className="win-card-wrapper" >
          <img className="win-card" src={renderCard()} alt="card img" />
        </div>
        </div>}

 <div className={showFooter ? "win-footer show-footer" : "win-footer"}>
      <p className="win-description">
        {getPrizeOption}
        </p>
      {
        couponTxt !== '' && prize.type === 'VOUCHER'
        && (
          <p
            onClick={() => onCopyToClipboard()}
            className={`win-code-coupon ${copyToClipBoardClass}`}
          >
            {couponTxt}
            <CopySvg />
          </p>
        )
      }
        {getPrizeDescription()}
      <Button onClick={onClick}>
      {getPrizeBtn()}
      </Button>
    </div>
    </Fragment> 
  )
}

export default WinPage;