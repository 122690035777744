import  { useEffect } from 'react'
import { useUIEvents } from '../../service/uiService';


const PrizeReveal = () => {
  const { setCurrentView } = useUIEvents();

  useEffect(() => {
    setTimeout(() => {
   setCurrentView('WIN')
    }, 2500);
  }, [setCurrentView])

  return (
    null
  )
}

export default PrizeReveal