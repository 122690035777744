function CopySvg(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke="#494B52" strokeWidth={2} d="M1 7.571h11.429V19H1z" />
      <path d="M15.19 12.429H19V1H7.571v3.81" stroke="#494B52" strokeWidth={2} />
    </svg>
  )
}

export default CopySvg
