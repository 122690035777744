import create from 'zustand'

export const customerObj = {
  "customer": {
      "action": "Other-AuthOcapi",
      "queryString": "c_app=true&c_wishlist=cb2dffa92a733efd12ccb16551&client_id=62bc8b34-0ca9-45a9-97e8-1888042da4e4&ocapi_version=v19_5",
      "locale": "de_DE",
      "_v": "19.5",
      "_type": "customer",
      "auth_type": "registered",
      "creation_date": "2020-04-20T13:20:16.000Z",
      "customer_id": "acSysQe5eJSeh8RacnM4WQuGWL",
      "customer_no": "00189518",
      "email": "tlp@demodern.de",
      "enabled": true,
      "first_name": "Timo",
      "last_login_time": "2021-11-11T19:36:25.085Z",
      "last_modified": "2021-11-11T19:36:26.791Z",
      "last_name": "Langpeter",
      "last_visit_time": "2021-11-11T19:36:25.085Z",
      "login": "tlp@demodern.de",
      "previous_login_time": "2021-11-11T19:36:25.085Z",
      "previous_visit_time": "2021-11-11T19:36:25.085Z",
      "title": "Herr",
      "c_loyaltyActive": true,
      "c_loyaltyCardID": "16032422997000200",
      "c_loyaltyRegistrationVerified": true,
      "c_loyaltySites": "nl,de",
      "c_loyaltyUserInfo": "{\"de\":{\"loyaltyCardID\":\"16032422997000200\",\"loyaltyActive\":true,\"loyaltyRegistrationVerified\":true,\"cvcCode\":842886,\"transitionalStatus\":{\"loyaltyCardId\":\"16032422997000200\",\"coins\":7795,\"conversionFactor\":0.01,\"maxDiscountFactor\":0.7,\"hearts\":450,\"coinsPending\":0,\"loyaltyRank\":\"CREW\",\"accountStatus\":\"ACTIVE\",\"cvcCode\":842886},\"lastCheckLoyaltyRank\":1636659386791,\"connectionError\":false},\"nl\":{\"isRefusedToBeLoyaltyMember\":false,\"howManyTimesPopupWasShown\":1}}",
      "c_preferredPaymentMethod": "CREDIT_CARD",
      "c_sscSyncResponseText": ["Waiting for update SC UUID", "Successfully Exported"],
      "c_sscSyncStatus": "exported",
      "c_sscid": "0037a00001KjHinAAF",
      "c_customerGroups": ["DE", "Everyone", "NativeApp", "NativeApp_DE", "PICK_UP_COLOGNE", "Registered", "app_and_loyalty_allmembers", "loyalty_all_members", "loyalty_crew_member", "test_cologne"],
      "JWT": "Bearer eyJfdiI6IjEiLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfdiI6IjEiLCJleHAiOjE2NTUxMjU0MjQsImlhdCI6MTY1NTEyMzYyNCwiaXNzIjoiNjJiYzhiMzQtMGNhOS00NWE5LTk3ZTgtMTg4ODA0MmRhNGU0Iiwic3ViIjoie1wiX3ZcIjpcIjFcIixcImN1c3RvbWVyX2luZm9cIjp7XCJjdXN0b21lcl9pZFwiOlwiYmV4ZE1RWnd6amhycDNlcGNmamh4YnJYQ2FcIixcImd1ZXN0XCI6ZmFsc2UsXCJndWVzdF9jdXN0b21lcl9pZFwiOlwiYmNySW1vTllndXhQYXBnUlViZTVuWkNOd3VcIixcInZpc2l0X2lkXCI6XCI2YjE1NjA3YWMwN2Q2MGM4NjcxY2QxZDA1YVwifX0ifQ.dl8XgldAVo8SGRDrrSAdnbD_tnRnfYwIrohjhsPW78JgTif2kukQqnB74RgKHRx6U5CTBee8ktTVwqnmtguRT4jxFgHxTD4LDIEAkvtLAB-SBHKgePojx3YsEMfmFT1qB0jlLEyS9BDSnbJMFZCDc3gIbxWQAVrKAOdzk8OzvlTR5cFk7v3MUQGBSW61oEOJbKLltjB3yo9YnFD6SXL_iHqLdDjY0rnsgVJSNhwNy4B6Y2NpG_mOX_gOmYm_m7hJzKuY4zU90SGc-GYkbBKfRPK3GthTr0LNXVsknydirpsZDI1hlBjrCxNz689-oguluia0RY_EBqjgiVgk3sP8cYPlvaOt5PhVtmtM_sKNwZssyJ0H2nLe96-XPGt30N0s3AKxCA1ThkkSq_6OsXroLeFzY3FpSzwYY007CE43orEwBKrMr_G6j10I-Pq3tdpMsO_uiUtFswdTCP0fbp7SWPh4T_3U7tGK2GDwr742tGeGu3oHolJECbd3eLINSt9f4MJiZGTLxvJEZR8tU8IK1qOO8xEqbwW2HGIbGgAjuIss3i-6b7OMNlV_Zhe2RprKi6tR7kvejCkKo-m4EXSpMqeGLuxWZm8FINErdEqaTtolwAL2Zp2hlh9q9NtwdKP4mxcQ4Ll4cB_6Ado7FMc116VCHKroMh0o8VZgggdb91Q",
      "success": true,
      "cookie": "__cq_dnt=1; dw_dnt=1; dwsid=k5gmjRsg-dyiPvrOWC3S6-xu3OJ45dwJGjNHfsA0yg3mddewCV7jyMkE9VsaYb8f8pl_C4vTJ6XnibxmjeOsWw==; dwanonymous_f489fce3a63fde16454e4c1b6c85eee9=acw00Q9MN0aIdZNr7VPlJb6NRK; sid=8EbS8nYWq5F6OOTUojYxMLzvqH9OFoKJQuQ,dwcustomer_f489fce3a63fde16454e4c1b6c85eee9=acSysQe5eJSeh8RacnM4WQuGWL; Version=1; Comment=\"Demandware customer cookie for site Sites-snse-DE-AT-Site\"; Max-Age=0; Expires=Thu, 01-Jan-1970 00:00:10 GMT; Path=/; Secure; SameSite=None, dwsid=k5gmjRsg-dyiPvrOWC3S6-xu3OJ45dwJGjNHfsA0yg3mddewCV7jyMkE9VsaYb8f8pl_C4vTJ6XnibxmjeOsWw==; Path=/; Secure; HttpOnly; SameSite=None, __cq_dnt=1; Path=/; Secure; SameSite=None, dw_dnt=1; Path=/; Secure; SameSite=None"
  },
  navigator: (view:string) => console.log('Go to ', view)
}

const URL_REQUEST = { 
  'STG': 'https://stg.ugc.appsocial.snipes.com/v3/five-million-clique-campaign/',
  'PROD': 'https://ugc.appsocial.snipes.com/v3/five-million-clique-campaign/'
}

// This is used for detecting the current env.
// So it's the "URL of the app itself"
// and not the endpoint that will be called.
const APP_URL = { 
  'STG': 'stg.five-million-clique-campaign.appsocial.snipes.com',
  'PROD': 'five-million-clique-campaign.appsocial.snipes.com'
}

type RequestData = {
  urlRequest: string,
  prize: {
    status: string,
    type: string,
    code: string
  },
  couponTxt: string,
  prizeOptions: {
    [key: string]: string; 
  },
  JWToken: string,
  requestDone: boolean,
  setRequestDone:() => void, 
  setJWToken: (token:string) => void,
  requestPrize: () => void,
  requestSecondCall: () => void,
  setURLRequest: (debug:boolean) => void
}

export const useRequestData = create<RequestData>((set,get) => ({
  urlRequest: URL_REQUEST.STG,
  requestDone: false,
  prize: {
    status: "",
    type: "",
    code: ""
  },
  couponTxt: '',
  prizeOptions: {
    VOUCHER: "15% off at SNIPES*",
    GIFT_CARD: "100€ SNIPES Giftcard",
    GIFT_CARD_FLATRATE: "SNIPES Shopping Flatrate",
    E_BIKE: "SNIPES Edition E-Bike",
    NY_TRIP: "Jackpot: Trip to NY!",
  },
  JWToken: '',
  setRequestDone: () => {
    set({requestDone: true})
  },
  setJWToken: token => set({JWToken: token}),
  requestPrize: () => {
    const JWTtoken = get().JWToken;
    fetch(get().urlRequest,{
        headers: new Headers({
          'Authorization': JWTtoken, 
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .then(response => response.json())
      .then(data => {
        //  data = {status:'PENDING', type:'VOUCHER', code: 'demodern'}  // TEST all prizes
        if(!data.detail) set({prize: data})
      }).catch(err=>{
        console.error(err)
      });
  },
  requestSecondCall: () => {
    fetch(get().urlRequest,{
        method: 'POST',
        headers: new Headers({
          'Authorization': get().JWToken, 
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.code) {
          set({couponTxt: data.code})
        }
      }).catch(err=>{
        console.error(err)
      });
  },
  setURLRequest: (debug) => {
    if (window.location.host === APP_URL.STG || debug) {
      set({urlRequest: URL_REQUEST.STG})
    } else {
      set({urlRequest: URL_REQUEST.PROD})
    }
  }
}))