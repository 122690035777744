import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Fragment, useEffect, useState } from 'react';
import './App.css';
import { useSnipesApp } from './SnipesApp';
import { useRequestData, customerObj } from './service/requestService';
import { useUIEvents } from "./service/uiService";
import IntroPage from './views/Intro';
import PrizeReveal from "./views/PrizeReveal";
import WinPage from "./views/Win";
import NoRegisteredUser from "./components/NoRegisteredUser";

function App() {
  const { currentView, setCurrentView} = useUIEvents();
  const { prize, requestPrize, setJWToken, requestDone, setRequestDone, setURLRequest } = useRequestData();
  const [noUser, setNoUser] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const debug = urlParams.get('debug') !== null ? true : false;

  // Test
  if (debug) {
    /* @ts-ignore */
    window.SnipesApp = customerObj;
  }

  let SnipesApp = useSnipesApp();

  useEffect(() => {
    if (SnipesApp && SnipesApp.customer.auth_type !== 'registered') {
      setNoUser(true);
    }
  }, [SnipesApp,noUser,setNoUser])

  useEffect(() => {
    setURLRequest(debug);

    if (SnipesApp && SnipesApp.customer && !requestDone) {
      setJWToken(SnipesApp.customer.JWT);
      requestPrize();
      setRequestDone();
    }
  },[SnipesApp, requestPrize, setJWToken, debug, setRequestDone, requestDone, setURLRequest])

  const renderPrize = () => prize.type !== '' ? `product_win/${prize.type}.jpg` : ''


  useEffect(() => {
    switch(prize.status) {
      case 'PENDING':
        setCurrentView('INTRO');
        break;
      case 'REVEALED':
          setCurrentView('PRIZEREVEAL');
        break;
      default:
        if (!SnipesApp && !requestDone)
          setCurrentView('INTRO');
    }
  },[prize, setCurrentView, SnipesApp, requestDone])

  return (
    <div className={currentView === 'PRIZEREVEAL' || currentView === 'WIN' ? 'App revealed' : 'App'}>
      {noUser ? (
      <NoRegisteredUser/>
        ): ( <Fragment>
           <img className="mcxsp-logo" src="logo.png" alt="" />
          <div className="win-container">
                <img src={renderPrize()} alt="win img" className="win-img"/>
              </div>
          <SwitchTransition>
            <CSSTransition
              key={currentView}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="content-container">
                {currentView === 'INTRO' && <IntroPage />}
                {currentView === 'PRIZEREVEAL' && <PrizeReveal />}
                {currentView === 'WIN' && <WinPage />}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Fragment>)}
                </div>
    
  );
}

export default App;
