import { Fragment, useEffect } from 'react';
import { ScratchCanvas } from '../../components/ScratchCanvas';
import { useRequestData } from '../../service/requestService';
import { useUIEvents } from '../../service/uiService';
import { useSnipesApp } from '../../SnipesApp';
import './style.css';

const IntroPage = () => {
  const { FINISHED_UNCOVERED_PERCENTAGE, setCurrentView, uncoveredPercentage } = useUIEvents();
  const { prize } = useRequestData()
  const SnipesApp = useSnipesApp();

  useEffect(()=> {
    if (uncoveredPercentage >= FINISHED_UNCOVERED_PERCENTAGE) {
      setTimeout(()=>{
        if(prize.type === 'LOSS'){
          setCurrentView('LOSE');
        } else {
          setCurrentView('PRIZEREVEAL');
        }
      },2000)
    }
  },[FINISHED_UNCOVERED_PERCENTAGE, uncoveredPercentage, setCurrentView, prize]);

  const renderScratch = () => {
    if(SnipesApp) {
      return (
          <ScratchCanvas
            imagePricePath= {renderPrize()}
            imageScratchingPath='scratch-field.jpg'
            width={217}
            height={217}
          />)
    }
    return (<p className="not-supported-message">
        Please update your SNIPES app to participate!
      </p>)
  }

  const renderPrize = () => {
    
   return  prize.status !== '' ? `product_scratching/${prize.type}.jpg` : "";
  }

  const openContentPage = () => {
    SnipesApp.navigate('Content Page', {
      page: {
        url: 'https://www.snipes.com/content/win.html',
        prevRoute: 'Feed',
      },
    }) 
  }

  return (
    <Fragment>
      <img className="action-image" src="scratch_it_up.png" alt="" />
      <p className="description-text">
      Celebrate the SNIPES CLIQUE and check your one-time chance to win<span className="description-text__highlight"> great prizes.</span>
      </p>
      <div className="scrath-canvas">
        { renderScratch() }
      </div>
      <p className="action-text">
      Scratch until the image is visible.
      </p>
      <p className="terms-conditions-text" onClick={() => openContentPage()}>
      By entering the competition you accept our terms and conditions. 
      </p>
    </Fragment>
  )
}

export default IntroPage;