import React, { Fragment } from 'react'
import Button from './Button'
import { useSnipesApp } from '../SnipesApp'

const NoRegisteredUser = () => {
  let SnipesApp = useSnipesApp();

  return (
    <Fragment>
          <img className="mcxsp-logo" src="logo.png" alt="" />
        <div className="content-container">
          <img className="action-image" src="scratch_it_up.png" alt="" />
        </div>
      <p className="description-text">
      Celebrate the SNIPES CLIQUE and check your one-time chance to win<span className="description-text__highlight"> great prizes.</span>
      </p>
        <div className="no-user-container">
          <p className="action-text">You must login or register to use this feature.</p>
          <Button onClick={() => SnipesApp.navigate('Intro')}>Login or Register</Button>
        </div>
      </Fragment>
  )
}

export default NoRegisteredUser